import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../context/userContext";

import MenuButton from "../MenuButton";

import { HiHome } from "react-icons/hi";
import { MdAttachMoney } from "react-icons/md";
import { IoLinkOutline } from "react-icons/io5";
import { TbClipboardText } from "react-icons/tb";
// import { BiCalendarStar } from "react-icons/bi";
import { BsPersonFill } from "react-icons/bs";
import { RiArrowLeftRightFill } from "react-icons/ri";
import { useLocation } from "react-router-dom";
import extractIcon from "../../../assets/extract-icon.png";
import { affiliateIds } from "../../../models/affiliate-ids";

interface MenuTopButtonsProps {
  goPage: (page: string, mixName: string) => void;
}

const MenuTopButtons: React.FC<MenuTopButtonsProps> = ({ goPage }) => {
  // const [expandPromotionsMenu, setExpandPromotionsMenu] = useState(false);
  const [expandReportMenu, setExpandReportMenu] = useState(false);
  const [expandManagerMenu, setExpandManagerMenu] = useState(false);

  const { userData } = useContext(UserContext);

  const location = useLocation();

  const isAffiliateManager = (smarticoId: number) => {
    const ids: number[] = [
      40282,
      38762,
      100397,
      285898,
      58490,
      ...Object.values(affiliateIds).map((affId) => affId.smarticoId),
    ];

    return ids.includes(smarticoId);
  };

  useEffect(() => {
    switch (location.pathname) {
      // case "/scpa":
      //   setExpandPromotionsMenu(true);
      //   break;
      case "/performance":
        setExpandReportMenu(true);
        break;
      case "/manager/performance":
        setExpandManagerMenu(true);
        break;
      case "/manager/management":
        setExpandManagerMenu(true);
        break;
      default:
        return;
    }
  }, [location]);

  return (
    <div>
      <MenuButton
        onClick={() => goPage("/", "inicio")}
        paths={["/"]}
        icon={<HiHome />}
        title="Início"
      />
      <MenuButton
        onClick={() => goPage("/withdraw", "saque")}
        paths={[
          "/withdraw",
          "/withdraw-pj",
        ]}
        icon={<MdAttachMoney />}
        title="Saque"
      />
      <MenuButton
        onClick={() => goPage("/extract", "extrato")}
        paths={["/extract"]}
        icon={
          <div className="extract-content">
            <img src={extractIcon} alt="Extrato" className="extract-icon" />
          </div>
        }
        title="Extrato"
      />
      <MenuButton
        onClick={() => setExpandReportMenu(!expandReportMenu)}
        paths={["/performance"]}
        icon={<TbClipboardText />}
        stateSubmenu={expandReportMenu}
        title="Relatórios"
        submenus={[
          {
            path: "/performance",
            title: "Performance do Afiliado",
            mixName: "performance",
          },
        ]}
      />
      <MenuButton
        onClick={() => goPage("/integrations", "integrações")}
        paths={["/integrations"]}
        icon={<RiArrowLeftRightFill />}
        title="Integrações"
      />
      {/* <MenuButton
        onClick={() => setExpandPromotionsMenu(!expandPromotionsMenu)}
        paths={["/scpa"]}
        icon={<BiCalendarStar />}
        stateSubmenu={expandPromotionsMenu}
        title="Promoções"
        submenus={[
          {
            path: "/scpa",
            title: "SCPA - Motograu",
            mixName: "scpa",
          },
        ]}
      /> */}
      <MenuButton
        onClick={() => goPage("/link", "links")}
        paths={["/link"]}
        icon={<IoLinkOutline />}
        title="Links"
      />
      {(userData.type === 3 || userData.type === 5) && (
        <MenuButton
          onClick={() => setExpandManagerMenu(!expandManagerMenu)}
          paths={["/manager/performance", "/manager/management"]}
          icon={<BsPersonFill />}
          stateSubmenu={expandManagerMenu}
          title="Gerente"
          submenus={
            isAffiliateManager(userData.smartico_id)
              ? [
                  {
                    path: "/manager/performance",
                    title: "Relatório de performance",
                    mixName: "affiliate-report",
                  },
                  {
                    path: "/manager/management",
                    title: "Gestão de afiliados",
                    mixName: "affiliate-management",
                  },
                ]
              : [
                  {
                    path: "/manager/performance",
                    title: "Relatório de performance",
                    mixName: "affiliate-report",
                  },
                ]
          }
        />
      )}
    </div>
  );
};

export default MenuTopButtons;
