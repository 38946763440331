//*Roteamento de páginas
import { Router } from 'react-router-dom';
import { UserProvider } from './context/userContext';
import { WarningProvider } from './context/warningContext';
import Routes from './Routes/routes';
import history from './services/history';

//*Estilização
import { GlobalStyle } from "./styles/global";
import { ChakraProvider } from '@chakra-ui/react';
import theme from './styles/theme';

//*Serviços
import Interceptors from './components/Interceptors';
import HubSpotChat from 'components/HubSpotChat';

function App() {

  return (
    <WarningProvider>
      <UserProvider>
        <Interceptors />
        <Router history={history}>
          <ChakraProvider theme={theme}>
            <GlobalStyle />
            <Routes />
            <HubSpotChat />
          </ChakraProvider>
        </Router>
      </UserProvider>
    </WarningProvider>
  );
}

export default App;
