import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';

//Componentes
import HeaderComponent from 'components/HeaderComponent';
import TableWithdraw from 'components/Tables/TableWithdraw';
import MobileTableWithdraw from 'components/Tables/MobileTableWithdraw';
import Pagination from 'components/Pagination';
import LoadingScreen from 'components/LoadingScreen';

//Tipos
import { WithdrawRequests } from 'components/Tables/TableWithdraw';

//Úteis
import endPoints from 'utils/endPoints';
import { useHistory } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { UserContext } from 'context/userContext';

//Estilização
import { Container, Wrapper, PaginationBar, Subheader, ModalButtons, SubContainer } from './styles';
import { nfIcon } from 'assets/nfIcon.js';
import { cashPayIcon } from 'assets/cashPay.js';

// Mixpanel
import mixPanelTrack from '../../../mixpanel';

interface IRequest {
  id: number,
  date_created: string,
  bank: string,
  agency: string,
  account: string,
  value: number,
  status: number,
  reason: string,
  invoiceNumber: string
}

const Withdraw: React.FC = () => {
  const [requests, setRequests] = useState<WithdrawRequests[]>([]);
  const [page, setPage] = useState(1);
  const [nPages, setNPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const { userData } = useContext(UserContext);
  const [showMobileButtons, setShowMobileButtons] = useState(false);

  const toast = useToast();
  const { invoice } = endPoints;
  const history = useHistory();


  useEffect(() => {
    getSaques();
    // eslint-disable-next-line
  }, [])

  const getSaques = async (page: number = 1) => {
    let url = invoice + `?page=${page}&itemsPerPage=10`;

    axios.get(url).then((response) => {
      setRequests(response.data.data);
      calcPages(response.data.total, page);
      setPage(page)
    }).catch((e) => {
      console.log(e);
    })
  }

  const downloadFile = (request: IRequest) => {
    setLoading(true);

    const url = `${invoice}/${request.id}/1`;

    axios.get(url).then((response) => {
      let file = response.data.file;
      let name = 'Anexo EstrelaBet';
      var a = document.createElement("a");
      a.href = file;
      a.download = name;
      a.click();
      setLoading(false);

      mixPanelTrack('Saque - Baixar Anexo', { user_smartico: userData.smartico_id })
    }).catch(() => {
      setLoading(false);
      toast({
        title: 'Erro ao baixar Anexo, tente novamente mais tarde',
        status: 'error',
        position: 'top',
        isClosable: true,
      });
    });
  }

  const calcPages = (totalItems: number, currentPage: number) => {
    let holder = totalItems / 10;
    let nOfPages = Math.ceil(holder);
    setNPages(nOfPages);
  }

  const goPage = async (page: string, mixName: string) => {
    mixPanelTrack("Saque - Opção de Saque", { option: mixName, user_smartico: userData.smartico_id });
    history.push(page)
  }

  return (
    <Container translate="no">
      <LoadingScreen show={loading} />
      <SubContainer>
        <div className='header'>
          <HeaderComponent
            title="SAQUE"
            subtitle="Minhas Requisições de Saque"
            buttons={
              <>
                <div className="desktop-buttons">
                  <button
                    onClick={() => { goPage("/withdraw-pj", "NF") }}
                  >
                    {nfIcon}
                    <span>Saque NF</span>
                  </button>
                </div>
                <div className="mobile-buttons">
                  <button className="mobile-button" onClick={() => setShowMobileButtons(true)}>
                    {cashPayIcon}
                    <span className="mobile-text">Saque</span>
                  </button>
                </div>
              </>
            }
          />
        </div>
        {showMobileButtons &&
          <ModalButtons>
            <div onClick={() => setShowMobileButtons(false)} className="close-wrapper"></div>
            <div className="modal">
              <button className="button-mobile" onClick={() => { goPage("/withdraw-pj", "NF") }}>
                {nfIcon}
                Saque NF
              </button>
            </div>
          </ModalButtons>
        }
        <Wrapper>
          <Subheader>
            <p>Minhas Requisições de Saque</p>
          </Subheader>
          {requests.length > 0 && <TableWithdraw requests={requests} downloadFile={downloadFile} />}
          {requests.length > 0 && <MobileTableWithdraw requests={requests} />}
          {!(requests.length > 0) &&
            <div className="no-requests">
              {cashPayIcon}
              <span className="empty-text">Você não possui Requisições de Saque.</span>
            </div>
          }
          <PaginationBar>
            <Pagination total={nPages} currentPage={page} getSaques={getSaques} />
          </PaginationBar>
        </Wrapper>
      </SubContainer>
    </Container>
  );
}

export default Withdraw;